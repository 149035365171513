<template>
  <div>
    <v-card-title>Schulungen</v-card-title>

    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="userTrainings"
          :search="search"
          v-model="selected"
          single-select
          mobile-breakpoint="0"
          @click:row="clickRow"
          sort-by="name"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="item.status === 'created'"
                  v-bind="attrs" v-on="on"
                  color="primary">mdi-circle-outline</v-icon>
                <v-icon
                  v-else-if="item.status === 'unlocked'"
                  v-bind="attrs" v-on="on"
                  color="primary">mdi-lock-open-variant</v-icon>
                <v-icon
                  v-else-if="item.status === 'locked'"
                  v-bind="attrs" v-on="on"
                  color="primary">mdi-lock</v-icon>
              </template>
              <span v-if="item.status === 'created'">erstellt</span>
              <span v-else-if="item.status === 'unlocked'">entsperrt</span>
              <span v-else-if="item.status === 'locked'">gesperrt</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      
      <v-col
        md="6"
        cols="12"
      >
        <v-card-title
          v-if="selected.length == 0"
        >Keine Schulung ausgewählt</v-card-title>
        <v-data-table
          v-else
          :headers="submissionsHeaders"
          :items="submissionsByTrainingId(selectedTraining.id)"
          mobile-breakpoint="0"
          sort-by="datetime"
          sort-desc
        >
          <template v-slot:[`item.passed`]="{ item }">
              <v-icon
                v-if="Boolean(item.result.correct >= item.result.questions)"
                color="primary"
              >mdi-check-circle</v-icon>
              <v-icon
                v-else
                color="primary"
              >mdi-circle-outline</v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-card-title class="pl-0">{{ selectedTraining.name }}</v-card-title>
              <v-spacer/>
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    v-bind="attrs" v-on="on"
                    @click="goToExam()"
                  >
                    <v-icon>mdi-text-box-check-outline</v-icon>
                  </v-btn>
                </template>
                <span>Prüfung ablegen</span>
              </v-tooltip>
            </v-toolbar>
          </template>
        </v-data-table>
        <v-card-subtitle class="text-left">{{ selectedTraining.description }}</v-card-subtitle>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { AuthPath, ProjectsActions, ProjectsGetters, ProjectsPath, TrainingsActions, TrainingsGetters, TrainingsPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'TrainingsUser',
  
  components: {
  },

  mounted: function() {
    this[ProjectsActions.REFRESH_USERS]();
    this[ProjectsActions.REFRESH_TRAININGS]();
    this[TrainingsActions.REFRESH]()
      .then(() => {
        const trainingId = this.$route.params.trainingId
        if (trainingId) {
          this.selected.push(this.userTrainings.find(t => t && t.id == trainingId));
        }
      });
  },

  data: () => {
    return {
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Beschreibung', align: 'start', sortable: true, value: 'description' },
        { text: 'Status', align: 'center', sortable: false, value: 'status' },
      ],
      submissionsHeaders: [
        { text: 'Versuch', align: 'start', sortable: true, value: 'datetime' },
        { text: 'Ergebnis', align: 'right', sortable: false, value: 'passed' },
      ],
      
      search: '',
      selected: [],
    }
  },

  watch: {
    route(val) {
      const trainingId = val.params.trainingId
      if (trainingId) {
          this.selected.push(this.userTrainings.find(t => t && t.id == trainingId));
      } else {
        this.selected = []
      }
    },

    selectedTraining(val) {
      if (val.id) {
        if (this.$route.params.trainingId === undefined) {
          this.$router.push(`/trainings/${val.id}`)
        }
      } else {
        if (this.$route.params.trainingId) {
          this.$router.push('/trainings')
        }
      }
    }
  },

  computed: {
    route: function() {
      return this.$route
    },

    selectedTraining: function() {
      if(this.selected.length <= 0) {
        return { id: -1 };
      }

      this[TrainingsActions.REFRESH_QUESTIONS]()
      this[TrainingsActions.REFRESH_OPTIONS]()
      this[TrainingsActions.REFRESH_SUBMISSIONS]()
      return this.selected[0]
    },

    userTrainings: function() {
      return this.trainingsByUserID(this.user.id);
    },

    ...mapState(AuthPath, { user: 'data' }),

    ...mapState(TrainingsPath, [ 'trainings' ]),

    ...mapGetters(TrainingsPath, {
      questionsByTrainingId: TrainingsGetters.QUESTIONS_BY_TRAINING_ID,
      optionsByQuestionId: TrainingsGetters.OPTIONS_BY_QUESTION_ID,
      trainingsByUserID: TrainingsGetters.TRAININGS_BY_USER_ID,
      submissionsByTrainingId: TrainingsGetters.SUBMISSIONS_BY_TRAINING_ID
    }),

    ...mapGetters(ProjectsPath, {
      projectIdsByUserId: ProjectsGetters.PROJECT_IDS_BY_USER_ID,
      trainingsByProjectId: ProjectsGetters.TRAININGS_BY_PROJECT_ID
    })
  },

  methods: {
    clickRow: function(item, row) {
      if(this.selected.some(e => e.id === item.id)) {
        row.select(false);
      } else {
        row.select(true);
      }
    },
    goToExam: function() {
      if (this.selectedTraining && this.selectedTraining.id) {
        this.$router.push(`/trainings/${this.selectedTraining.id}/exam`)
      }
    },

    sort: function(array) {
      return array.slice().sort((a, b) => a.order - b.order);
    },

    ...mapActions(TrainingsPath, [
      TrainingsActions.REFRESH,
      TrainingsActions.REFRESH_QUESTIONS, 
      TrainingsActions.REFRESH_OPTIONS,
      TrainingsActions.REFRESH_SUBMISSIONS
    ]),

    ...mapActions(ProjectsPath, [
      ProjectsActions.REFRESH_USERS,
      ProjectsActions.REFRESH_TRAININGS
    ])
  }
}
</script>
