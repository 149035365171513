<template>
  <div>
    <v-img
      :src="require('../assets/logo.jpg')"
      class="my-12 mx-8"
      contain
      height="120"
    />

    <v-row>
      <v-col md="4" cols="1">
      </v-col>
      <v-col md="4" cols="10">
            <v-form
              @submit="registerUser"
            >
              <v-text-field
                v-model="token"
                label="Token"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="otp"
                label="Einladungscode"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="pin"
                label="Pin"
                :type="pinShow ? '' : 'password'"
                :append-icon="pinShow ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="pinShow = !pinShow"
              ></v-text-field>
              <v-text-field
                v-model="pinConfirm"
                label="Pin bestätigen"
                :type="pinConfirmShow ? '' : 'password'"
                :append-icon="pinConfirmShow ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="pinConfirmShow = !pinConfirmShow"
              ></v-text-field>
              <v-btn
                type="submit"
              >Registrieren</v-btn>
            </v-form>
        <v-alert
          v-if="notificationShow"
          dense
          border="top"
          :color="notificationColor"
          style="color: white"
          class="mt-4"
        >{{ notificationText }}</v-alert>
      </v-col>
      <v-col md="4" cols="1">
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
import { AuthPath, AuthActions, Actions, Getters, NOTIFICATION_TYPE } from '../store/definitions'

export default {
  name: 'Register',
  components: {
  },

  mounted: function() {
    const query = this.$route.query;
    if ((query == undefined)
      || (query.token == undefined)
      || (query.otp == undefined)) {
        this.$router.push('/')
    }
    this.token = query.token
    this.otp = query.otp
  },

  data: () => {
    return {
      tab: 1,
      token: '',
      otp: '',
      pin: '',
      pinConfirm: '',
      pinShow: false,
      pinConfirmShow: false
    }
  },

  computed: {
    notificationColor: function() {
      const type = this.notificationType
      return type == NOTIFICATION_TYPE.INFO ? 'green' : 'red'
    },

    ...mapGetters({
      notificationShow: Getters.NOTIFICATION_SHOW,
      notificationType: Getters.NOTIFICATION_TYPE,
      notificationText: Getters.NOTIFICATION_TEXT
    })
  },

  methods: {
    registerUser: function(event) {
      event.preventDefault()
      const { token, otp, pin, pinConfirm } = this;
      if (pin !== pinConfirm) {
        this[Actions.NOTIFICATION_SHOW_ERROR]('Pins stimmen nicht überein')
      } else {
        this[AuthActions.REGISTER]({ token, otp, pin })
          .then(() => this.$router.push(`/login?token=${token}`))
          .then(() => {
            this[Actions.NOTIFICATION_SHOW_INFO]('Erfolgreich registriert')
          })
          .catch(() => {
            this[Actions.NOTIFICATION_SHOW_ERROR]('Fehler beim Registrieren')
          })
      }
    },

    ...mapActions(AuthPath, [ AuthActions.REGISTER ]),
    ...mapActions([
      Actions.NOTIFICATION_SHOW_ERROR,
      Actions.NOTIFICATION_SHOW_INFO
    ])
  }
}
</script>
