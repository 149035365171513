<template>
  <div>
    <v-card-title>Projekte</v-card-title>

    <v-dialog v-model="addProjectDialog" max-width="800">
      <v-card>
        <v-card-title>Projekt erstellen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="12">
          <v-text-field
            label="Name"
            v-model="addProjectDialogName"
          >
          </v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addProjectDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addProjectDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeProjectDialog" max-width="800">
      <v-card>
        <v-card-title>Projekt entfernen</v-card-title>
        <v-card-text v-if="removeProjectDialogProject != null">
          {{ removeProjectDialogProject.name }}
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeProjectDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeProjectDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="projects"
      :search="search"
      mobile-breakpoint="0"
      sort-by="name"
      @click:row="clickProject"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer v-if="isAdmin"></v-spacer>

          <v-tooltip left v-if="isAdmin">
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="primary"
                v-bind="attrs" v-on="on"
                @click="addProjectDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Projekt erstellen</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="isAdmin">
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              v-bind="attrs" v-on="on"
              @click.stop="removeProjectDialogProject = item; removeProjectDialog = true"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>entfernen</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Actions, AuthActions, AuthGetters, AuthPath, ProjectsActions, ProjectsGetters, ProjectsPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'ProjectDetails',
  
  components: {
  },

  mounted: function() {
    this[ProjectsActions.REFRESH]()
    this[AuthActions.REFRESH]()
  },

  data: () => {
    return {
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Adresse', align: 'start', sortable: true, value: 'address' },
        { text: 'Kontakt', align: 'start', sortable: true, value: 'contact' },
        { text: 'Status', align: 'end', sortable: false, value: 'status' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' },
      ],
      search: '',
      addProjectDialog: false,
      addProjectDialogName: '',
      removeProjectDialog: false,
      removeProjectDialogProject: null
    }
  },

  computed: {
    projects: function() {
      const isAdmin = this.isAdmin
      const allProjects = this[ProjectsGetters.PROJECTS]
      const userid = this.userId
      const userProjects = this[ProjectsGetters.PROJECTS_BY_USER_ID](userid)
      if (isAdmin) {
        return allProjects
      }
      return userProjects
    },

    ...mapGetters(ProjectsPath, [
      ProjectsGetters.PROJECTS,
      ProjectsGetters.PROJECTS_BY_USER_ID
    ]),

    ...mapGetters(AuthPath, {
      isAdmin: AuthGetters.IS_ADMIN,
      userId: AuthGetters.USERID
    }),
  },

  methods: {
    addProjectDialogSave: function() {
      if (this.isAdmin) {
        const project = {
          name: this.addProjectDialogName
        }
        this[ProjectsActions.ADD_PROJECT](project)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Projekt hinzugefügt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Projekt nicht hinzugefügt'))
      }
      this.addProjectDialog = false
      this.addProjectDialogName = ''
    },

    removeProjectDialogSave: function() {
      if (this.isAdmin && this.removeProjectDialogProject) {
        this[ProjectsActions.REMOVE_PROJECT](this.removeProjectDialogProject.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Projekt entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Projekt nicht entfernt'))
      }
      this.removeProjectDialog = false
      this.removeProjectDialogProject = null
    },

    clickProject: function(project) {
      this.$router.push(`/projects/${project.id}`)
    },

    ...mapActions(ProjectsPath, [
      ProjectsActions.REFRESH,
      ProjectsActions.ADD_PROJECT,
      ProjectsActions.REMOVE_PROJECT
    ]),

    ...mapActions(AuthPath, [ AuthActions.REFRESH ]),

    ...mapActions([
      Actions.NOTIFICATION_SHOW_ERROR,
      Actions.NOTIFICATION_SHOW_INFO
    ])
  }
}
</script>
