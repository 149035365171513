var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_vm._v("Schulungen")]),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userTrainings,"search":_vm.search,"single-select":"","mobile-breakpoint":"0","sort-by":"name"},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'created')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-circle-outline")]):(item.status === 'unlocked')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-variant")]):(item.status === 'locked')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock")]):_vm._e()]}}],null,true)},[(item.status === 'created')?_c('span',[_vm._v("erstellt")]):(item.status === 'unlocked')?_c('span',[_vm._v("entsperrt")]):(item.status === 'locked')?_c('span',[_vm._v("gesperrt")]):_vm._e()])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[(_vm.selected.length == 0)?_c('v-card-title',[_vm._v("Keine Schulung ausgewählt")]):_c('v-data-table',{attrs:{"headers":_vm.submissionsHeaders,"items":_vm.submissionsByTrainingId(_vm.selectedTraining.id),"mobile-breakpoint":"0","sort-by":"datetime","sort-desc":""},scopedSlots:_vm._u([{key:"item.passed",fn:function(ref){
var item = ref.item;
return [(Boolean(item.result.correct >= item.result.questions))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.selectedTraining.name))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.goToExam()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-check-outline")])],1)]}}])},[_c('span',[_vm._v("Prüfung ablegen")])])],1)]},proxy:true}],null,true)}),_c('v-card-subtitle',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.selectedTraining.description))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }