<template>
  <div>
    <v-toolbar flat>
      <v-card-title class="pl-0">Prüfung: {{ training.name }}</v-card-title>
      <v-spacer/>
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs" v-on="on"
            @click="abort()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Prüfung abbrechen</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="pt-0 text-justify">
      {{ training.description }}
    </v-card-text>

    <v-row>
      <v-col cols="0" md="2" lg="3"></v-col>
      <v-col cols="12" md="8" lg="6">
        <v-card>
          <v-carousel
            :continuous="false"
            hide-delimiters
          >
            <v-carousel-item
              v-for="question in questions"
              :key="question.id"
              class="px-12"
            >
              <v-card-title class="justify-center">{{ question.text }}</v-card-title>
              <v-card-text>{{ question.description }}</v-card-text>
              <v-card-text>
                <v-radio-group v-model="selectedOptions[question.id]">
                  <v-radio
                    v-for="option in sort(optionsByQuestionId(question.id))"
                    :key="option.id"
                    :label="option.text"
                    :value="option.id"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-carousel-item>
            
            <v-carousel-item
              :key="9999"
              class="px-12"
            >
              <v-card-title class="justify-center">&Uuml;bersicht</v-card-title>
              <v-data-table
                :headers="overviewHeaders"
                :items="selectedOptionsFiltered"
                disable-pagination
                hide-default-footer
              >
              </v-data-table>
              <v-btn color="primary"
                @click.stop="submitSubmission()"
              >Abgeben</v-btn>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
      <v-col cols="0" md="2" lg="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AuthGetters, AuthPath, TrainingsActions, TrainingsGetters, TrainingsPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'TrainingExam',
  
  components: {
  },

  mounted: function() {
    this[TrainingsActions.REFRESH]();
    this[TrainingsActions.REFRESH_QUESTIONS]()
    .then(() => {
      this.selectedOptions = []
      for (const question of this.questions) {
        this.selectedOptions[question.id] = -1
      }
    });
    this[TrainingsActions.REFRESH_OPTIONS]();
  },

  data: () => {
    return {
      selectedOptions: [],
      overviewHeaders: [
        { text: 'Frage', align: 'start', sortable: true, value: 'text' },
        { text: 'Antwort', align: 'end', sortable: false, value: 'option' },
      ]
    }
  },

  computed: {
    selectedOptionsFiltered() {
      const optionIds = this.selectedOptions
      let res = []
      for (let i = 0; i < optionIds.length; i++)
      {
        const optionId = optionIds[i];
        if (!optionId) {
          continue;
        }
        const question = this.questions.find(q => q.id == i)
        const option = this.optionsByQuestionId(i).find(o => o.id == optionId)
        res.push({
          questionId: i,
          optionId: optionId,
          text: question ? question.text : '',
          option: option ? option.text : ''
        })
      }
      return res
    },

    trainingId() {
      return this.$route.params.trainingId
    },

    training() {
      return this.trainingById(this.trainingId)
        || { name: '' }
    },

    questions() {
      return this.questionsByTrainingId(this.trainingId)
    },

    ...mapGetters(TrainingsPath, {
      trainingById: TrainingsGetters.TRAINING_BY_ID,
      questionsByTrainingId: TrainingsGetters.QUESTIONS_BY_TRAINING_ID,
      optionsByQuestionId: TrainingsGetters.OPTIONS_BY_QUESTION_ID
    }),

    ...mapGetters(AuthPath, [ AuthGetters.USERID ])
  },

  methods: {
    abort() {
      this.$router.push(`/trainings/${this.training.id}`)
    },

    sort: function(array) {
      return array.slice().sort((a, b) => a.order - b.order);
    },

    submitSubmission() {
      const submission = {
        trainingId: this.trainingId,
        userId: this[AuthGetters.USERID],
      }
      let submissionId = -1
      this[TrainingsActions.ADD_SUBMISSION](submission)
      .then((storedSubmission) => {
        submissionId = storedSubmission.id
      })
      .then(async () => {
        for (const option of this.selectedOptionsFiltered) {
          await this[TrainingsActions.ADD_SUBMISSION_OPTION]({
            trainingSubmissionId: submissionId,
            trainingQuestionOptionId: option.optionId
          })
        }
        this.$router.push(`/trainings/${this.trainingId}`)
      })
    },

    ...mapActions(TrainingsPath, [
      TrainingsActions.REFRESH,
      TrainingsActions.REFRESH_QUESTIONS,
      TrainingsActions.REFRESH_OPTIONS,
      TrainingsActions.ADD_SUBMISSION,
      TrainingsActions.ADD_SUBMISSION_OPTION
    ])
  }
}
</script>
