<template>
  <div>
    <v-card-title>Schulungen</v-card-title>

    <v-dialog v-model="addTrainingDialog" max-width="800">
      <v-card>
        <v-card-title>Schulung erstellen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
          <v-text-field
            label="Name"
            v-model="addTrainingDialogName"
          >
          </v-text-field>
          </v-col>
          <v-col cols="6">
          <v-text-field
            label="Beschreibung"
            v-model="addTrainingDialogDescription"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addTrainingDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addTrainingDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeTrainingDialog" max-width="800">
      <v-card>
        <v-card-title>Schulung entfernen</v-card-title>
        <v-card-text v-if="removeTrainingDialogTraining != null">
          {{ removeTrainingDialogTraining.name }}
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeTrainingDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeTrainingDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editQuestionDialog" max-width="800">
      <v-card v-if="editQuestionDialog">
        <v-card-title>Frage bearbeiten</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
          <v-text-field
            label="Text"
            v-model="editQuestionDialogQuestion.text"
          >
          </v-text-field>
          </v-col>
          <v-col cols="6">
          <v-text-field
            label="Beschreibung"
            v-model="editQuestionDialogQuestion.description"
          ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="editQuestionDialogCorrectOptionId"
            >
              <v-radio
                v-for="option in sort(optionsByQuestionId(editQuestionDialogQuestion.id))"
                :key="option.id"
                :label="option.text"
                :value="option.id"
              >
                <template v-slot:label>
                  <v-text-field
                    v-model="option.text"
                  ></v-text-field>
                  <v-btn
                    v-if="!positionIsFirst(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                    icon
                    small
                    @click.stop="positionUp(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn>
                  <v-btn
                    v-if="!positionIsLast(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                    icon
                    small
                    @click.stop="positionDown(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn>
                  <v-btn
                    icon
                    small
                    @click.stop="removeOptionDialogShow(option)"
                  ><v-icon>mdi-delete</v-icon></v-btn>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="editQuestionDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="addOption">Option hinzufügen</v-btn>
          <v-btn color="primary" @click="editQuestionDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeQuestionDialog" max-width="800">
      <v-card>
        <v-card-title>Frage entfernen</v-card-title>
        <v-card-text v-if="removeQuestionDialogQuestion != null">
          Frage "{{ removeQuestionDialogQuestion.text }}"
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeQuestionDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeQuestionDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeOptionDialog" max-width="800">
      <v-card>
        <v-card-title>Option entfernen</v-card-title>
        <v-card-text v-if="removeOptionDialogOption != null">
          Option "{{ removeOptionDialogOption.text }}"
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeOptionDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeOptionDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="trainings"
          :search="search"
          v-model="selected"
          single-select
          mobile-breakpoint="0"
          @click:row="clickRow"
          sort-by="name"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    v-bind="attrs" v-on="on"
                    @click="addTrainingDialog = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Schulung erstellen</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  color="primary"
                  v-bind="attrs" v-on="on"
                  @click.stop="duplicateTraining(item)"
                >
                  <v-icon>mdi-content-duplicate</v-icon>
                </v-btn>
              </template>
              <span>duplizieren</span>
            </v-tooltip>
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  color="primary"
                  v-bind="attrs" v-on="on"
                  @click.stop="removeTrainingDialogTraining = item; removeTrainingDialog = true"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>entfernen</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      
      <v-col
        md="6"
        cols="12"
      >
        <v-card-title>{{ selectedTraining.name }}</v-card-title>
          <v-text-field
            v-if="selected.length > 0"
            class="mx-4"
            label="Name"
            v-model="selectedTraining.name"
            :outlined="editTrainingName"
            :readonly="!editTrainingName"
            :prepend-icon="editTrainingName ? 'mdi-close' : ''"
            @click:prepend="editTrainingName = false"
            :append-icon="editTrainingName ? 'mdi-send' : 'mdi-pencil'"
            @click:append="saveTraining(); editTrainingName = !editTrainingName"
          ></v-text-field>
          
          <v-text-field
            v-if="selected.length > 0"
            class="mx-4"
            label="Beschreibung"
            v-model="selectedTraining.description"
            :outlined="editTrainingDescription"
            :readonly="!editTrainingDescription"
            :prepend-icon="editTrainingDescription ? 'mdi-close' : ''"
            @click:prepend="editTrainingDescription = false"
            :append-icon="editTrainingDescription ? 'mdi-send' : 'mdi-pencil'"
            @click:append="saveTraining(); editTrainingDescription = !editTrainingDescription"
          ></v-text-field>
          
          <v-textarea
            v-if="selected.length > 0"
            class="mx-4"
            label="Inhalt"
            v-model="selectedTraining.content"
            rows="4"
            :outlined="editTrainingContent"
            :readonly="!editTrainingContent"
            :prepend-icon="editTrainingContent ? 'mdi-close' : ''"
            @click:prepend="editTrainingContent = false"
            :append-icon="editTrainingContent ? 'mdi-send' : 'mdi-pencil'"
            @click:append="saveTraining(); editTrainingContent = !editTrainingContent"
          ></v-textarea>
        
        <v-container
          v-for="item in sort(questionsByTrainingId(selectedTraining.id))"
          :key="item.id"
          class="px-0"
        >
          <v-card-title>{{ item.text }}<v-spacer></v-spacer>
            <v-btn
              v-if="!positionIsFirst(questionsByTrainingId(selectedTraining.id), item)"
              small fab color="primary"
              @click.stop="questionPositionUp(questionsByTrainingId(selectedTraining.id), item)"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn
              v-if="!positionIsLast(questionsByTrainingId(selectedTraining.id), item)"
              small fab color="primary"
              @click.stop="questionPositionDown(questionsByTrainingId(selectedTraining.id), item)">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn
              small fab color="primary"
              @click.stop="removeQuestionDialogShow(item)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn
              small fab color="primary"
              @click.stop="editQuestionDialogShow(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="text-left">{{ item.description }}</v-card-subtitle>
          <v-card-text>
            <v-radio-group :value="correctIdFromArray(optionsByQuestionId(item.id))">
              <v-radio
                v-for="option in sort(optionsByQuestionId(item.id))"
                :key="option.id"
                :label="option.text"
                :value="option.id"
                readonly
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-container>
        
        <v-container v-if="selected.length !== 0">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              fab
              color="primary"
              @click="addQuestion"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Actions, AuthGetters, AuthPath, TrainingsActions, TrainingsGetters, TrainingsPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'TrainingsAdmin',
  
  components: {
  },

  mounted: function() {
    this[TrainingsActions.REFRESH]();
  },

  data: () => {
    return {
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Beschreibung', align: 'start', sortable: true, value: 'description' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' },
      ],
      
      search: '',
      selected: [],
      noneSelected: {
          name: 'Keine Schulung ausgewählt',
          description: '',
          status: 'created',
          questions: []
      },
      addTrainingDialog: false,
      addTrainingDialogName: '',
      addTrainingDialogDescription: '',
      removeTrainingDialog: false,
      removeTrainingDialogTraining: null,
      editTrainingName: false,
      editTrainingDescription: false,
      editTrainingContent: false,
      editQuestionDialog: false,
      editQuestionDialogQuestion: null,
      editQuestionDialogCorrectOptionId: -1,
      removeQuestionDialog: false,
      removeQuestionDialogQuestion: null,
      removeOptionDialog: false,
      removeOptionDialogOption: null
    }
  },

  computed: {
    selectedTraining: function() {
      if(this.selected.length <= 0) {
        return this.noneSelected;
      }

      this[TrainingsActions.REFRESH_QUESTIONS]();
      this[TrainingsActions.REFRESH_OPTIONS]();
      return this.selected[0];
    },

    ...mapState(TrainingsPath, [ 'trainings' ]),

    ...mapGetters(AuthPath, { isAdmin: AuthGetters.IS_ADMIN }),

    ...mapGetters(TrainingsPath, {
      questionsByTrainingId: TrainingsGetters.QUESTIONS_BY_TRAINING_ID,
      optionsByQuestionId: TrainingsGetters.OPTIONS_BY_QUESTION_ID
    })
  },

  methods: {
    clickRow: function(item, row) {
      if(this.selected.some(e => e.id === item.id)) {
        row.select(false);
      } else {
        row.select(true);
      }
    },

    positionIsFirst: function(array, item) {
      return !array.some(e => e.position < item.position)
    },

    positionIsLast: function(array, item) {
      return !array.some(e => e.position > item.position)
    },

    sort: function(array) {
      return array.slice().sort((a, b) => a.position - b.position);
    },

    correctIdFromArray: function(array) {
      if (array.length <= 0) {
        return -1
      }
      const correct = array.find(e => e.correct)
      if (!correct) {
        return -1
      }
      return correct.id
    },

    questionPositionUp: function(array, item) {
      this.positionUp(array, item, this[TrainingsActions.EDIT_QUESTION])
    },

    questionPositionDown: function(array, item) {
      this.positionDown(array, item, this[TrainingsActions.EDIT_QUESTION])
    },

    positionUp: function(array, item, saveAction = null) {
      const sorted = this.sort(array)
      const index = sorted.findIndex(e => e.id == item.id)
      if (index < 1) {
        return;
      }
      const prevItem = sorted[index - 1]
      this.positionSwitch(item, prevItem)
      if (saveAction !== null) {
        saveAction(array.find(e => e.id == prevItem.id))
        saveAction(array.find(e => e.id == item.id))
      }
    },

    positionDown: function(array, item, saveAction = null) {
      const sorted = this.sort(array)
      const index = sorted.findIndex(e => e.id == item.id)
      if (index >= (array.length - 1)) {
        return;
      }
      const prevItem = sorted[index + 1]
      this.positionSwitch(item, prevItem)
      saveAction(array.find(e => e.id == prevItem.id))
      saveAction(array.find(e => e.id == item.id))
    },

    positionSwitch: function(a, b) {
      const aPosition = a.position;
      const bPosition = b.position;
      b.position = aPosition;
      a.position = bPosition;
    },

    addTrainingDialogSave: function() {
      if (this.isAdmin) {
        const training = {
          name: this.addTrainingDialogName,
          description: this.addTrainingDialogDescription,
          content: '',
          status: 'created'
        }
        this[TrainingsActions.ADD_TRAINING](training)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Schulung hinzugefügt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht hinzugefügt'))
      }
      this.addTrainingDialog = false
      this.addTrainingDialogName = ''
      this.addTrainingDialogDescription = ''
    },

    removeTrainingDialogSave: function() {
      if (this.isAdmin && this.removeTrainingDialogTraining) {
        this[TrainingsActions.REMOVE_TRAINING](this.removeTrainingDialogTraining.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Schulung entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht entfernt'))
      }
      this.removeTrainingDialog = false
      this.removeTrainingDialogTraining = null
    },

    addQuestion: function() {
      const questions = this.sort(this.questionsByTrainingId(this.selectedTraining.id))
      const position = questions[questions.length - 1].position + 1
      const question = {
        trainingId: this.selectedTraining.id,
        position: position,
        text: 'Neue Frage',
        description: ''
      }
      this[TrainingsActions.ADD_QUESTION](question)
    },

    addOption: function() {
      const options = this.sort(this.optionsByQuestionId(this.editQuestionDialogQuestion.id))
      const position = options.length == 0 ? 0 : options[options.length - 1].position + 1
      const option = {
        trainingQuestionId: this.editQuestionDialogQuestion.id,
        position: position,
        text: 'Neue Option',
        correct: false
      }
      this[TrainingsActions.ADD_OPTION](option)
    },

    editQuestionDialogShow: function(item) {
      this.editQuestionDialogQuestion = item
      this.editQuestionDialogCorrectOptionId = this.correctIdFromArray(this.optionsByQuestionId(item.id))
      this.editQuestionDialog = true
    },

    editQuestionDialogSave: function() {
      if (this.isAdmin && this.editQuestionDialog) {
        this[TrainingsActions.EDIT_QUESTION](this.editQuestionDialogQuestion)
        for (const option of this.optionsByQuestionId(this.editQuestionDialogQuestion.id)) {
          option.correct = this.editQuestionDialogCorrectOptionId == option.id
          this[TrainingsActions.EDIT_OPTION](option)
        }
        this.editQuestionDialog = false
        this.editQuestionDialogQuestion = null
      }
    },

    removeQuestionDialogShow: function(item) {
      this.removeQuestionDialogQuestion = item
      this.removeQuestionDialog = true
    },

    removeQuestionDialogSave: function() {
      if (this.isAdmin && this.removeQuestionDialogQuestion) {
        this[TrainingsActions.REMOVE_QUESTION](this.removeQuestionDialogQuestion.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Frage entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Frage nicht entfernt'))
      }
      this.removeQuestionDialog = false
      this.removeQuestionDialogQuestion = null
    },

    removeOptionDialogShow: function(item) {
      this.removeOptionDialogOption = item
      this.removeOptionDialog = true
    },

    removeOptionDialogSave: function() {
      if (this.isAdmin && this.removeOptionDialogOption) {
        this[TrainingsActions.REMOVE_OPTION](this.removeOptionDialogOption.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Option entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Option nicht entfernt'))
      }
      this.removeOptionDialog = false
      this.removeOptionDialogOption = null
    },

    duplicateTraining: function(originalTraining) {
      if (this.isAdmin) {
        this[TrainingsActions.DUPLICATE_TRAINING](originalTraining.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Schulung dupliziert'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht dupliziert'))
      }
    },

    saveTraining: function() {
      if (this.selectedTraining !== null &&
         (this.editTrainingName || this.editTrainingDescription || this.editTrainingContent)) {
        this[TrainingsActions.EDIT_TRAINING](this.selectedTraining)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Schulung gespeichert.'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht gespeichert.'))
      }
    },

    ...mapActions(TrainingsPath, [
      TrainingsActions.REFRESH,
      TrainingsActions.REFRESH_QUESTIONS, 
      TrainingsActions.REFRESH_OPTIONS,
      TrainingsActions.DUPLICATE_TRAINING,
      TrainingsActions.EDIT_TRAINING,
      TrainingsActions.EDIT_QUESTION,
      TrainingsActions.EDIT_OPTION,
      TrainingsActions.ADD_TRAINING,
      TrainingsActions.ADD_QUESTION,
      TrainingsActions.ADD_OPTION,
      TrainingsActions.REMOVE_TRAINING,
      TrainingsActions.REMOVE_QUESTION,
      TrainingsActions.REMOVE_OPTION
    ]),

    ...mapActions([
      Actions.NOTIFICATION_SHOW_INFO,
      Actions.NOTIFICATION_SHOW_ERROR
    ])
  }
}
</script>
