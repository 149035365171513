<template>
  <div>
    <v-card-title>{{ project.name }}</v-card-title>

    <v-dialog v-model="addProjectUserDialog" max-width="800">
      <v-card>
        <v-card-title>Projektmitarbeiter hinzuf&uuml;gen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
          <v-combobox
            label="Mitarbeiter"
            :items="allUsersFormatted"
            v-model="addProjectUserDialogUser"
          >
          </v-combobox>
          </v-col>
          <v-col cols="6">
          <v-combobox
            label="Rolle"
            :items="projectUserRoles"
            v-model="addProjectUserDialogRole"
          ></v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addProjectUserDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addProjectUserDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeProjectUserDialog" max-width="800">
      <v-card>
        <v-card-title>Projektmitarbeiter entfernen</v-card-title>
        <v-card-text v-if="removeProjectUserDialogUser != null">
          {{ removeProjectUserDialogUser.name }} {{ removeProjectUserDialogUser.firstName }} ({{ removeProjectUserDialogUser.role }})
          wirklich aus dem Projekt entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeProjectUserDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeProjectUserDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addProjectTrainingDialog" max-width="800">
      <v-card>
        <v-card-title>Projektschulung hinzuf&uuml;gen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="12">
          <v-combobox
            label="Schulung"
            :items="allTrainingsFormatted"
            v-model="addProjectTrainingDialogTraining"
          >
          </v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addProjectTrainingDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addProjectTrainingDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeProjectTrainingDialog" max-width="800">
      <v-card>
        <v-card-title>Projektschulung entfernen</v-card-title>
        <v-card-text v-if="addProjectTrainingDialogTraining != null">
          {{ addProjectTrainingDialogTraining.name }} wirklich aus dem Projekt entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeProjectTrainingDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeProjectTrainingDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col md="6" cols="12">
          <v-data-table
            :headers="teamHeaders"
            :items="usersByProjectId(project.id)"
            item-key="projectUserId"
            group-by="role"
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title class="pl-0">Mitarbeiter</v-card-title>

                <v-spacer></v-spacer>

                <v-tooltip left v-if="isAdmin">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      v-bind="attrs" v-on="on"
                      @click="addProjectUserDialog = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Mitarbeiter hinzuf&uuml;gen</span>
                </v-tooltip>
              </v-toolbar>
            </template>

            <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
              <td @click="toggle" :colspan="headers.length" class="text-left">
                <v-btn small icon :ref="group">
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ group }}
              </td>
            </template>

            <template v-slot:[`item.finished`]>
              <v-icon
                color="primary"
              >mdi-circle-outline</v-icon>
            </template>
            
            <template v-slot:[`item.phone`]="{ item }">
              {{ item.phone }}
              <v-btn
                icon
                small
                @click.stop="call(item.phone)"
              ><v-icon>mdi-phone</v-icon></v-btn>
            </template>
      
            <template v-slot:[`item.email`]="{ item }">
              {{ item.email }}
              <v-btn
                icon
                small
                @click.stop="email(item.email)"
              ><v-icon>mdi-email</v-icon></v-btn>
            </template>
      
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="isAdmin">
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    v-bind="attrs" v-on="on"
                    @click="removeProjectUserDialogUser = item; removeProjectUserDialog = true"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Mitarbeiter entfernen</span>
              </v-tooltip>
            </template>
          </v-data-table>
      </v-col>
      
      <v-col md="6" cols="12">
          <v-data-table
            :headers="trainingsHeaders"
            :items="trainingsByProjectId(project.id)"
            item-key="projectTrainingId"
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title class="pl-0">Schulungen</v-card-title>

                <v-spacer></v-spacer>

                <v-tooltip left v-if="isAdmin">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      v-bind="attrs" v-on="on"
                      @click="addProjectTrainingDialog = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Mitarbeiter hinzuf&uuml;gen</span>
                </v-tooltip>
              </v-toolbar>
            </template>

            <template v-slot:[`item.finished`]="item">
              <v-progress-linear
                v-if="isAdmin"
                :value="item.finished"
                height="25"
                :striped="item.finished < 100"
                :color="item.finished <= 20 ? 'red' : 'primary'"
              >
                <template v-slot:default="{ value }">
                  <span class="white--text">{{ Math.ceil(value) }}%</span>
                </template>
              </v-progress-linear>

              <v-icon
                v-else
                color="primary"
              >mdi-circle-outline</v-icon>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="isAdmin">
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    v-bind="attrs" v-on="on"
                    @click="removeProjectTrainingDialogTraining = item; removeProjectTrainingDialog = true"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Schulung entfernen</span>
              </v-tooltip>
            </template>
          </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Actions, AuthActions, AuthGetters, AuthPath, ProjectsActions, ProjectsGetters, ProjectsPath, TrainingsActions, TrainingsPath, UsersActions, UsersGetters, UsersPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'ProjectDetails',
  
  components: {
  },

  mounted: function() {
    this[AuthActions.REFRESH]();
    this[UsersActions.REFRESH]();
    this[TrainingsActions.REFRESH]();
    this[ProjectsActions.REFRESH_USERS]();
    this[ProjectsActions.REFRESH_TRAININGS]();
    this[ProjectsActions.REFRESH]();
  },

  data: () => {
    return {
      teamHeadersAdmin: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Vorname', align: 'start', sortable: true, value: 'firstName' },
        { text: 'OK', align: 'start', sortable: true, value: 'finished' },
        { text: 'Rolle', align: 'start', sortable: true, value: 'role' },
        { text: 'E-Mail', align: 'end', sortable: true, value: 'email' },
        { text: 'Telefon', align: 'start', sortable: true, value: 'phone' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
      ],
      teamHeadersUser: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Vorname', align: 'start', sortable: true, value: 'firstName' },
        { text: 'Rolle', align: 'start', sortable: true, value: 'role' },
        { text: 'E-Mail', align: 'end', sortable: true, value: 'email' },
        { text: 'Telefon', align: 'end', sortable: true, value: 'phone' },
      ],
      trainingsHeadersAdmin: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Beschreibung', align: 'start', sortable: true, value: 'description' },
        { text: 'Abgeschlossen', align: 'start', sortable: true, value: 'finished' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
      ],
      trainingsHeadersUser: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Beschreibung', align: 'start', sortable: true, value: 'description' },
        { text: 'Abgeschlossen', align: 'end', sortable: true, value: 'finished' }
      ],
      projectUserRoles: [
        'Bauleiter',
        'Mitarbeiter'
      ],
      addProjectUserDialog: false,
      addProjectUserDialogUser: null,
      addProjectUserDialogRole: null,
      removeProjectUserDialog: false,
      removeProjectUserDialogUser: null,
      addProjectTrainingDialog: false,
      addProjectTrainingDialogTraining: null,
      addProjectTrainingDialogRole: null,
      removeProjectTrainingDialog: false,
      removeProjectTrainingDialogTraining: null
    }
  },

  computed: {
    teamHeaders: function() {
      const isAdmin = this.isAdmin
      return isAdmin ? this.teamHeadersAdmin : this.teamHeadersUser
    },
    
    trainingsHeaders: function() {
      const isAdmin = this.isAdmin
      return isAdmin ? this.trainingsHeadersAdmin : this.trainingsHeadersUser
    },

    project: function() {
      return this.projects.find(e => e.id == this.$route.params.id) || { 'name': '' };
    },

    allUsersFormatted() {
      const us = this.allUsers
      return us.map(function(u) {
        return { id: u.id, text: u.name + ' ' + u.firstName }
      }).sort((a, b) => (a.text > b.text) ? 1 : -1)
    },

    allTrainingsFormatted() {
      const ts = this.trainings
      return ts.map(function(t) {
        return { id: t.id, text: t.name }
      }).sort((a, b) => (a.text > b.text) ? 1 : -1)
    },

    ...mapState(ProjectsPath, [ 'projects' ]),

    ...mapState(TrainingsPath, [ 'trainings' ]),

    ...mapGetters(UsersPath, { allUsers: UsersGetters.USERS }),

    ...mapGetters(AuthPath, { isAdmin: AuthGetters.IS_ADMIN }),

    ...mapGetters(ProjectsPath, {
      usersByProjectId: ProjectsGetters.USERS_BY_PROJECT_ID,
      trainingsByProjectId: ProjectsGetters.TRAININGS_BY_PROJECT_ID
    })
  },

  methods: {
    call: function(phone) {
      window.open('tel:' + phone, '_self');
    },

    addProjectUserDialogSave: function() {
      if (this.isAdmin) {
        const projectUser = {
          projectId: this.project.id,
          userId: this.addProjectUserDialogUser.id,
          role: this.addProjectUserDialogRole
        }
        this[ProjectsActions.ADD_PROJECT_USER](projectUser)
        .then(() => {
          this[Actions.NOTIFICATION_SHOW_INFO]('Mitarbeiter hinzugefügt')
        })
        .catch(() => {
          this[Actions.NOTIFICATION_SHOW_ERROR]('Mitarbeiter nicht hinzugefügt')
        })
      }
      this.addProjectUserDialog = false
      this.addProjectUserDialogUser = null
      this.addProjectUserDialogRole = null
    },

    removeProjectUserDialogSave: function() {
      if (this.isAdmin && this.removeProjectUserDialogUser) {
        this[ProjectsActions.REMOVE_PROJECT_USER](this.removeProjectUserDialogUser.projectUserId)
        .then(() => {
          this[Actions.NOTIFICATION_SHOW_INFO]('Mitarbeiter entfernt')
        })
        .catch(() => {
          this[Actions.NOTIFICATION_SHOW_ERROR]('Mitarbeiter nicht entfernt')
        })
      }
      this.removeProjectUserDialog = false
      this.removeProjectUserDialogUser = null
    },

    addProjectTrainingDialogSave: function() {
      if (this.isAdmin) {
        const projectTraining = {
          projectId: this.project.id,
          trainingId: this.addProjectTrainingDialogTraining.id
        }
        this[ProjectsActions.ADD_PROJECT_TRAINING](projectTraining)
        .then(() => {
          this[Actions.NOTIFICATION_SHOW_INFO]('Schulung hinzugefügt')
        })
        .catch(() => {
          this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht hinzugefügt')
        })
      }
      this.addProjectTrainingDialog = false
      this.addProjectTrainingDialogTraining = null
      this.addProjectTrainingDialogRole = null
    },

    removeProjectTrainingDialogSave: function() {
      if (this.isAdmin && this.removeProjectTrainingDialogTraining) {
        this[ProjectsActions.REMOVE_PROJECT_TRAINING](this.removeProjectTrainingDialogTraining.projectTrainingId)
        .then(() => {
          this[Actions.NOTIFICATION_SHOW_INFO]('Schulung entfernt')
        })
        .catch(() => {
          this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht entfernt')
        })
      }
      this.removeProjectTrainingDialog = false
      this.removeProjectTrainingDialogTraining = null
    },

    ...mapActions(AuthPath, [AuthActions.REFRESH]),

    ...mapActions(UsersPath, [ UsersActions.REFRESH ]),

    ...mapActions(TrainingsPath, [ TrainingsActions.REFRESH ]),

    ...mapActions(ProjectsPath, [
      ProjectsActions.REFRESH,
      ProjectsActions.REFRESH_USERS,
      ProjectsActions.REFRESH_TRAININGS,
      ProjectsActions.ADD_PROJECT_USER,
      ProjectsActions.REMOVE_PROJECT_USER,
      ProjectsActions.ADD_PROJECT_TRAINING,
      ProjectsActions.REMOVE_PROJECT_TRAINING
    ]),

    ...mapActions([
      Actions.NOTIFICATION_SHOW_INFO,
      Actions.NOTIFICATION_SHOW_ERROR
    ])
  }
}
</script>
